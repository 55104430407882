import { withSentryReactRouterV6Routing } from '@sentry/react';
import { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { PATH } from 'helpers/constant';
import useDocumentTitle from 'hooks/useDocumentTitle';

import store, { resetAllState, useAppSelector } from 'store';
import { AdaptedRoles, updateAuth } from 'store/authSlice';

import { roles } from 'components/assign-hosts-room/AssignHostsRoom';
import { AdminPanelPage } from 'pages/AdminPanelPage';
import { GameLoginPage } from 'pages/game-login/GameLoginPage';
import { AssignHostsPage } from 'pages/game-prepare/AssignHostsPage';
import { BreakoutRoomPage } from 'pages/game-prepare/BreakoutRoomPage';
import { TeamWaitingPage } from 'pages/game-prepare/TeamWaitingPage';
import { ViewGamePage } from 'pages/game-prepare/ViewGamePage';
import { CongratulationPage } from 'pages/gameplay/CongratulationPage';
import { GamePlayPage } from 'pages/gameplay/GamePlayPage';
import { MainStagePage } from 'pages/gameplay/MainStagePage';
import { ScoreSummaryWaitingPage } from 'pages/gameplay/ScoreSummaryWaiting';
import { TournamentPage } from 'pages/gameplay/TournamentPage';

import { GlobalLayout } from 'layouts/global/GlobalLayout';
import PrivateLayout from 'layouts/private/PrivateLayout';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

// TODO: change to something else
const DefaultRoute = `/login/host/game/none`;

const PrivateRoute = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);

  return token ? children : <Navigate to={DefaultRoute} />;
};

const PublicRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { token, role, gameId, refreshToken } = useAppSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    if (
      !!token &&
      /login.*game\//.test(location.pathname) &&
      role === AdaptedRoles[roles.GAMELEADER] &&
      id
    ) {
      if (gameId !== id) {
        store.dispatch(
          updateAuth({
            role,
            token,
            refreshToken,
            gameId: id,
          }),
        );
      }
      navigate(`/game/${id}`);
    } else {
      resetAllState();
    }
    setLoading(false);
  }, []);

  return !loading ? children : null;
};

const App = () => {
  const role = useAppSelector((state) => state.auth.role);
  const company = useAppSelector(
    (state) => state.game?.game?.customer?.company,
  );

  useDocumentTitle(role, company);

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path={PATH.main} element={<GlobalLayout />}>
          <Route
            path={PATH.gameLogin}
            element={
              <PublicRoute>
                <GameLoginPage />
              </PublicRoute>
            }
          />
          <Route path={PATH.teamWaiting} element={<TeamWaitingPage />} />
          <Route path={PATH.adminPanel} element={<AdminPanelPage />} />
          <Route
            path={PATH.privateMain}
            element={
              <PrivateRoute>
                <PrivateLayout />
              </PrivateRoute>
            }
          >
            <Route path={PATH.breakoutRoom} element={<BreakoutRoomPage />} />
            <Route path={PATH.assignHosts} element={<AssignHostsPage />} />
            <Route path={PATH.tournament} element={<TournamentPage />} />
            <Route
              path={PATH.scoreSummary}
              element={<TournamentPage scoreSummary />}
            />

            <Route path={PATH.mainStage} element={<MainStagePage />} />
            <Route path={PATH.gameplay} element={<GamePlayPage />} />
            <Route
              path={PATH.scoreSummaryWaiting}
              element={<ScoreSummaryWaitingPage />}
            />
            <Route
              path={PATH.congratulations}
              element={<CongratulationPage />}
            />
            <Route path={PATH.viewGame} element={<ViewGamePage />} />
            <Route path={PATH.viewGameWithRoom} element={<ViewGamePage />} />
          </Route>
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  );
};

export default App;
